/* src/components/FlipCard.css */

.flip-card {
  width: 600px;
  height: 500px;
  perspective: 1500px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s cubic-bezier(0.4, 0, 0.2, 1);
  transform-style: preserve-3d;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.flip-card.flipped .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: white;
  border-radius: 20px;
  padding: 20px;
  box-sizing: border-box;
}

.flip-card-front {
  background: linear-gradient(135deg, #007bff, #0f3877);
}

.flip-card-back {
  background: linear-gradient(135deg, #3498db, #2c3e50);
  transform: rotateY(180deg);
}
