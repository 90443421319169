.quiz-container {
    text-align: center;
    max-width: 100%;
    margin: 0 auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  @media (max-width: 600px) {
    .quiz-container {
      padding: 10px;
    }
  
    .option-button {
      font-size: 14px;
      padding: 8px 16px;
    }
  
    .next-question-button {
      width: 100%;
      padding: 10px;
      font-size: 14px;
    }
  }
  
  @media (min-width: 601px) and (max-width: 1024px) {
    .quiz-container {
      padding: 20px;
    }
  
    .option-button {
      font-size: 16px;
      padding: 10px 20px;
    }
  
    .next-question-button {
      width: 80%;
      font-size: 16px;
    }
  }
  
  .options-container {
    margin-top: 20px;
  }
  
  .option {
    margin-bottom: 10px;
  }
  
  .option-button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    text-align: left;
    transition: background-color 0.3s ease;
  }
  
  .option-button:hover {
    background-color: #0056b3;
  }
  
  .next-question-button {
    padding: 14px 28px;
    font-size: 16px;
    color: white;
    background: linear-gradient(135deg, #4facfe, #00f2fe);
    border: none;
    border-radius: 30px;
    cursor: pointer;
    transition: all 0.3s ease;
    display: inline-block;
    width: 160px; 
    height: 50px; 
    margin-top: 20px;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  }
  