/* Navbar.css */

.navbar-container {
  position: relative;
  width: 100%;
}

@media (max-width: 768px) {
  .navbar {
      flex-direction: column;
      text-align: center;
  }
}

@media (max-width: 480px) {
  .navbar-brand {
      font-size: 1.5rem;
  }
  .navbar {
      padding: 10px;
  }
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  background: transparent;
  z-index: 1000;
}

.navbar-logo {
  display: flex;
  align-items: center;
}

.navbar-logo .logo {
  height: 50px;
  margin-right: 20px;
}

.navbar h1 {
  font-size: 1.5rem;
  color: #123456;
}

.navbar-links {
  display: flex;
  gap: 20px;
}

.navbar-links a {
  text-decoration: none;
  color: #333;
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 20px;
  transition: background-color 0.3s ease;
}

.navbar-links a:hover {
  background-color: #f0f0f0;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  color: #333;
  padding: 10px 20px;
  border-radius: 20px;
  transition: background-color 0.3s ease;
}

.dropdown-button:hover {
  background-color: #f0f0f0;
}

.dropdown-content {
  display: none;
  position: absolute;
  left: 0;
  background-color: #fff;
  min-width: 160px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  z-index: 1000;
}

.dropdown-content a {
  padding: 10px 20px;
  text-decoration: none;
  display: block;
  color: #333;
  transition: background-color 0.3s ease;
  border-radius: 10px;
}

.dropdown-content a:hover {
  background-color: #f0f0f0;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.navbar-contact {
  font-size: 1rem;
  color: #123456;
}

.navbar.fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.9); 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
  animation: slide-down 0.3s ease;
  z-index: 1000; 
}

@keyframes slide-down {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}
