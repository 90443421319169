.trivia-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: 100vh;
  padding: 20px;
}

@media (max-width: 600px) {
  .trivia-container {
    flex-direction: column;
    padding: 10px;
  }

  .sidebar-button {
    width: 100%;
    margin: 10px 0;
  }

  .header-section {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (min-width: 601px) and (max-width: 1024px) {
  .trivia-container {
    padding: 20px;
  }

  .sidebar-button {
    width: 80%;
  }
}

.sidebar {
  display: none; /* Hide the sidebar since buttons are moved */
  flex-direction: column;
  margin-right: 20px;
}

.sidebar-button {
  padding: 10px 20px;
  font-size: 16px;
  margin: 0 10px; /* Adjust margin for better spacing */
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.sidebar-button.active {
  background-color: #0056b3;
}

.sidebar-button:hover {
  background-color: #0056b3;
}

.content-area {
  flex-grow: 1;
  padding: 20px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  justify-content: center;
  align-items: center;
}

.trivia-card {
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  padding: 40px 30px;
  margin-bottom: 20px;
  width: 100%;
  max-width: 600px;
  text-align: center;
  justify-content: center; 
  align-items: center;
  margin: 20px auto;
}

.trivia-card h2 {
  font-size: 26px;
  color: #34495e;
  margin-bottom: 20px;
}

.button-group {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.prev-button,
.next-button {
  padding: 14px 28px;
  font-size: 16px;
  color: white;
  margin-top: 20px;
  background: linear-gradient(45deg, #1d8cf8, #3358f4);
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 50px;
  text-align: center;
}

.prev-button:hover,
.next-button:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.header-section {
  margin-bottom: 20px;
  padding: 20px;
  background-color: #f8f9fa;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding-left: 80px;
  padding-right: 40px;
  margin-left: auto;
}

.header-section h1 {
  font-size: 32px;
  margin-top: 100px;
  color: #2c3e50;
  margin-bottom: 10px;
}

.header-section h3 {
  font-size: 18px;
  color: #34495e;
  margin-bottom: 20px;
  text-align: left;
}

.header-section h4 {
  font-size: 24px;
  color: #2c3e50;
  margin-bottom: 10px;
}

.header-section h5 {
  font-size: 18px;
  color: #34495e;
  margin-bottom: 20px;
  text-align: left;
}

.header-section h6 {
  font-size: 32px;
  color: #2c3e50;
  margin-bottom: 10px;
}

.header-section h7 {
  font-size: 18px;
  color: #34495e;
  margin-bottom: 20px;
  text-align: left;
}
