.acknowledgement-section {
  background-color: #f4f4f4;
  text-align: center;
  padding: 50px 20px;
  color: #333;
}

.navbar-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.navbar-logo img.logo {
  width: 50px;
  margin-right: 10px;
}

.acknowledgement-section h1 {
  font-size: 2rem;
  margin: 0;
}

.acknowledgement-section h3 {
  font-size: 1.5rem;
  color: #d9534f;
  margin-bottom: 15px;
}

.acknowledgement-section p {
  font-size: 1rem;
  line-height: 1.6;
  max-width: 600px;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .acknowledgement-section {
    padding: 40px 15px;
  }

  .navbar-logo img.logo {
    width: 40px;
    margin-right: 8px;
  }

  .acknowledgement-section h1 {
    font-size: 1.75rem;
  }

  .acknowledgement-section h3 {
    font-size: 1.25rem;
  }

  .acknowledgement-section p {
    font-size: 0.95rem;
  }
}

@media (max-width: 480px) {
  .acknowledgement-section {
    padding: 30px 10px;
  }

  .navbar-logo img.logo {
    width: 35px;
    margin-right: 5px;
  }

  .acknowledgement-section h1 {
    font-size: 1.5rem;
  }

  .acknowledgement-section h3 {
    font-size: 1.1rem;
  }

  .acknowledgement-section p {
    font-size: 0.9rem;
  }
}
